import React from 'react';
import { useForm } from 'react-hook-form';

import Layout from '../components/layout';
import Seo from '../components/seo';
import { Container, Row, Col } from 'react-grid-system';
import Hero from '../components/rows/hero/index';

import Banner from '../images/hero-images/research-hero.jpg';

export default function HealthcareProviderSurvey() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    console.log(data);
    fetch('/.netlify/functions/healthcare-provider-survey', {
      method: 'POST',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => console.log(res));
  };
  console.log(errors);

  const radioOptions = [
    {
      title:
        '1. Are your phone calls and inquiries responded to quickly and courteously?',
      key: 'callResponse',
    },
    {
      title: '2. Are you satisfied with our process for booking appointments?',
      key: 'bookingProcess',
    },
    {
      title: '3. Are you happy with our office/clinic hours?',
      key: 'hours',
    },
    {
      title: '4. Are the requisition/referral forms easy to use?',
      key: 'formUse',
    },
    {
      title: '5. Do you receive our reports in a timely fashion?',
      key: 'timelyReports',
    },
    {
      title: '6. Are PACE Cardiology reports detailed and informative?',
      key: 'reportsInformative',
    },
    {
      title:
        '7. Would you recommend PACE Cardiology to other patients who require cardiac care?',
      key: 'recommendPatients',
    },
    {
      title: '8. Would you recommend PACE Cardiology to your colleagues?',
      key: 'recommendColleagues',
    },
  ];

  return (
    <Layout>
      <Seo
        title="Healthcare provider survey"
        description="Healthcare provider survey."
      />
      <Hero title="Healthcare provider survey" banner={Banner} />
      <Container className="my-52">
        <Row>
          <Col md={6} sm={12} className="mx-auto">
            <h2 className="text-center">
              <span className="text-red-300">Your </span> feedback matters
            </h2>
            <p className="text-center">
              At PACE Cardiology, providing the highest standard of patient care
              is essential. That’s where your input comes in!
            </p>
          </Col>
        </Row>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="input">
            <label
              className="block font-medium text-gray-700 required"
              htmlFor="firstName"
            >
              First Name
            </label>
            <input
              type="text"
              name="First Name"
              {...register('FirstName', { required: true })}
              id="firstName"
              className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full border-gray-300 rounded-md"
            />
          </div>
          <div className="input">
            <label
              className="block font-medium text-gray-700 required"
              htmlFor="lastName"
            >
              Last Name
            </label>
            <input
              type="text"
              name="Last Name"
              id="lastName"
              {...register('LastName', { required: true })}
              className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full border-gray-300 rounded-md"
            />
          </div>
          <div className="input">
            <label
              className="block font-medium text-gray-700 required"
              htmlFor="memberNumber"
            >
              CPSO or Member Number
            </label>
            <input
              type="text"
              name="Member Number"
              id="memberNumber"
              {...register('MemberNumber', { required: true })}
              className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full border-gray-300 rounded-md"
            />
          </div>
          <div className="input">
            <label
              className="block font-medium text-gray-700 required"
              htmlFor="email"
            >
              Email Address
            </label>
            <input
              type="email"
              name="Email"
              id="email"
              {...register('Email', { required: true })}
              className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full border-gray-300 rounded-md"
            />
          </div>
          {radioOptions.map((radio, id) => (
            <div key={id}>
              <p>{radio.title}</p>
              {/* Yes */}
              <span className="radio">
                <label htmlFor={`${radio.key}Y`}>Yes</label>
                <input
                  {...register(`${radio.key}`, { required: true })}
                  type="radio"
                  id={`${radio.key}Y`}
                  name={`${radio.key}`}
                  value="Yes"
                  className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                />
              </span>
              {/* No */}
              <span className="radio">
                <label htmlFor={`${radio.key}N`}>No</label>
                <input
                  {...register(`${radio.key}`, { required: true })}
                  type="radio"
                  id={`${radio.key}N`}
                  name={`${radio.key}`}
                  value="No"
                  className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                />
              </span>
              {/* NA */}
              <span className="radio">
                <label htmlFor={`${radio.key}NA`}>N/A</label>
                <input
                  {...register(`${radio.key}`, { required: true })}
                  type="radio"
                  id={`${radio.key}NA`}
                  name={`${radio.key}`}
                  value="N/A"
                  className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                />
              </span>
            </div>
          ))}
          <div>
            <p>
              9. Would you be interested in a PACE Cardiology CME program? If
              so, would you prefer it to be in-person, or virtual?
            </p>
            <span className="radio">
              <label htmlFor="inPerson">In Person</label>
              <input
                {...register('cme', { required: true })}
                type="radio"
                id="inPerson"
                name="cme"
                value="inPerson"
                className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
              />
            </span>
            <span className="radio">
              <label htmlFor="Virtual">Virtual</label>
              <input
                {...register('cme', { required: true })}
                type="radio"
                id="Virtual"
                name="cme"
                value="Virtual"
                className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
              />
            </span>
          </div>
          <div>
            <label htmlFor="cmetopics">
              10. What CME topics would you be interested in?
            </label>
            <textarea
              {...register('cmetopics', {})}
              id="cmetopics"
              className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full border-gray-300 rounded-md"
            />
          </div>
          <div>
            <label htmlFor="presentTime">
              11. What times/days work best for CME presentations?
            </label>
            <textarea
              {...register('cmetimepresentations', {})}
              id="presentTime"
              className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full border-gray-300 rounded-md"
            />
          </div>
          <div>
            <label htmlFor="comments">
              12. We welcome your comments or questions – please feel free to
              share your input
            </label>
            <textarea
              {...register('comments', {})}
              id="comments"
              className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full border-gray-300 rounded-md"
            />
          </div>
          <div className="button-wrapper">
            <button type="submit" className="form-button red-button">
              Submit Form
            </button>
          </div>
        </form>
      </Container>
    </Layout>
  );
}
